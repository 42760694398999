import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { createNavigationContainerRef, NavigationContainer, NavigatorScreenParams } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { ReactNode, useEffect, useState } from 'react';
import { envs } from '../../env';
import { LoadingPage } from '../components/helperComponents/LoadingPage';
import { ServerIsDownPage } from '../components/helperComponents/ServerIsDownPage';
import { NotFound } from '../components/screens/NotFound';
import { SIGNED_IN_STATUS, useSignedInContext } from '../context/SignedInContext';
import { SignUp } from '../components/screens/Auth/SignUp';
import { SignIn } from '../components/screens/Auth/SignIn';
import { AccountSetup } from '../components/screens/AccountSetup/AccountSetup';
import { TabsNavigator, TabsNavigatorParamList } from './TabsNavigator';
import { trpc } from '../apiCalls/trpcClient';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { utils } from '../utils/utils';
import { UpdateApp } from '../components/helperComponents/UpdateApp';
import { EditProfile } from '../components/screens/Tabs/Settings/components/EditProfile';
import { AdminNavigator, AdminNavigatorParamList } from './AdminNavigator';
import { ForgotPassword } from '../components/screens/ForgetPassword/ForgetPassword';
import { VerifyForgetPassword } from '../components/screens/ForgetPassword/VerifyForgetPassword';
import { ResetPassword } from '../components/screens/ForgetPassword/ResetPassword';
import { getVersionNumber } from '../utils/getVersionNumber/getVersionNumber';
import ChangePassword from '../components/screens/Tabs/Settings/components/ChangePassword';
import ReferFriend from '../components/screens/Tabs/Settings/components/ReferFriend';
import ContactUs from '../components/screens/Tabs/Settings/components/ContactUs';
import TermsAndPrivacy from '../components/screens/Tabs/Settings/components/TermsAndPrivacy';
import AboutUs from '../components/screens/Tabs/Settings/components/AboutUs';


export type RootStackParamList = {
    Login: undefined
    SignUp: undefined
    AccountSetup: { phoneNumber: string, token?: string, skipVerification?: boolean }
    ForgotPassword: undefined,
    VerifyForgetPassword: { phoneNumber: string, token: string }
    ResetPassword: { token: string, code: string }
    Tabs: NavigatorScreenParams<TabsNavigatorParamList>;
    /* screens for settings tab */
    EditProfile: undefined,
    ChangePassword: undefined,
    ReferFriend: undefined,
    ContactUs: undefined,
    TermsAndPrivacy: undefined,
    AboutUs: undefined,
    Admin: NavigatorScreenParams<AdminNavigatorParamList>;
    NotFound: undefined
};

const Stack = createNativeStackNavigator<RootStackParamList>();

const navRef = createNavigationContainerRef<RootStackParamList>();

export const getRootNavRef = () => {
    return navRef.isReady() ? navRef : undefined;
};

const config: { screens: { [key in keyof RootStackParamList]: string | { path: string, screens: Object } } } = {
    screens: {
        Login: 'login',
        SignUp: 'signup',
        AccountSetup: 'accountsetup',
        ForgotPassword: 'forgotpassword',
        VerifyForgetPassword: 'verifyforgetpassword',
        ResetPassword: 'resetpassword',
        Tabs: {
            path: 'tabs',
            screens: {
                Home: 'home',
                Results: 'results',
                Sapphics: 'sapphics',
                Read: 'read',
                Settings: 'settings',
            },
        },
        EditProfile: 'editprofile',
        ChangePassword: 'changepassword',
        ReferFriend: 'referfriend',
        ContactUs: 'contactus',
        TermsAndPrivacy: 'termsandprivacy',
        AboutUs: 'aboutus',
        Admin: {
            path: 'admin',
            screens: {
                Analytics: 'analytics',
                QuarterQuestions: 'quarterquestions',
                UserSettings: 'usersettings',
                ModerateUserQuestions: 'moderateuserquestions',
                SynthesisArticles: 'synthesisarticles',
                ResourceArticles: 'resourcearticles',
                Notifications: 'notifications',
                AppData: 'appData',
            },
        },
        NotFound: '*',
    },
};

const linking = {
    prefixes: [
        envs.WEBSITE_BASE_URL,
    ],
    config,
};

const version = getVersionNumber().jsVersion;

const RootNavigation = ({ onReady }: { onReady: (isReady: boolean) => void }) => {
    const [serverIsDown, setServerIsDown] = useState<boolean | undefined>();
    const { isSignedIn, isAdmin, currentUserData, signedInStatus, setSignedInStatus, refetchCurrentUserData } = useSignedInContext();

    const {
        data: serverVersion,
        failureCount: serverVersionRequestFailureCount,
        isLoading: isLoadingServerVersion,
        refetch: refetchServerVersion,
    } = trpc.util.getVersion.useQuery(undefined, { retry: 2, enabled: !serverIsDown, refetchOnWindowFocus: false });

    //todo replace getServerVersion by useGetMinimumMobileJSVersion: check if backend return version number
    const { data: minimumMobileJSVersion } = trpc.util.getMinimumMobileJSVersion.useQuery(undefined, { enabled: isMobileApp && !serverIsDown, refetchOnWindowFocus: false });

    // Initial check for sign in status based on stored token.
    useEffect(() => {
        if (signedInStatus === SIGNED_IN_STATUS.loading && !serverIsDown) {
            MB_accessTokenUtils.getAccessToken()
                .then((accessToken) => {
                    if (accessToken) {
                        setSignedInStatus(SIGNED_IN_STATUS.signedIn);
                        // MB_accessTokenUtils.removeAccessToken();
                    } else {
                        setSignedInStatus(SIGNED_IN_STATUS.signedOut);
                    }
                });
        }
    }, [onReady, serverIsDown, setSignedInStatus, signedInStatus]);

    // Checks to see if server is down or not by pulling the backend version.
    useEffect(() => {
        if (serverVersion) {
            setServerIsDown(false);
            if (isSignedIn) {
                refetchCurrentUserData();
            }
        } else if (!serverIsDown && serverVersionRequestFailureCount >= 3) {
            setServerIsDown(true);
        }

        onReady(true);
    }, [onReady, signedInStatus, serverIsDown, isSignedIn, serverVersion, serverVersionRequestFailureCount, refetchCurrentUserData]);

    if (
        isMobileApp &&
        minimumMobileJSVersion &&
        utils.compareVersion(version, minimumMobileJSVersion.version) < 0
    ) {
        return <UpdateApp version={minimumMobileJSVersion.version} />;
    }

    if (serverIsDown) {
        return (
            <ServerIsDownPage
                onTryAgainPressed={refetchServerVersion}
                isLoading={isLoadingServerVersion}
            />
        );
    }

    if (signedInStatus === SIGNED_IN_STATUS.loading || (isSignedIn && currentUserData === undefined) || !serverVersion || (isMobileApp && !minimumMobileJSVersion)) {
        return <LoadingPage />;
    }

    function renderGuestRoutes(): ReactNode {
        if (!isSignedIn) {
            return (
                <React.Fragment>
                    <Stack.Screen name="Login" component={SignIn} />
                    <Stack.Screen name="SignUp" component={SignUp} />
                    <Stack.Screen name="AccountSetup" component={AccountSetup} />
                    <Stack.Screen name="ForgotPassword" component={ForgotPassword} />
                    <Stack.Screen name="VerifyForgetPassword" component={VerifyForgetPassword} />
                    <Stack.Screen name="ResetPassword" component={ResetPassword} />
                </React.Fragment>
            );
        }

        return null;
    }

    function renderSignedInRoutes(): ReactNode {
        if (isSignedIn) {
            if (isAdmin(currentUserData)) {
                return (
                    <React.Fragment>
                        <Stack.Screen name="Admin" component={AdminNavigator} />
                    </React.Fragment>
                );
            }
            return (
                <React.Fragment>
                    <Stack.Screen name="Tabs" component={TabsNavigator} />
                    <Stack.Screen name="EditProfile" component={EditProfile} />
                    <Stack.Screen name="ChangePassword" component={ChangePassword} />
                    <Stack.Screen name="ReferFriend" component={ReferFriend} />
                    <Stack.Screen name="ContactUs" component={ContactUs} />
                    <Stack.Screen name="TermsAndPrivacy" component={TermsAndPrivacy} />
                    <Stack.Screen name="AboutUs" component={AboutUs} />
                    <Stack.Screen name="AccountSetup" component={AccountSetup} />
                </React.Fragment>
            );
        }
    }

    return (
        <NavigationContainer
            linking={linking}
            ref={navRef}
        >
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                    gestureEnabled: false,
                }}
            >
                {renderGuestRoutes()}
                {renderSignedInRoutes()}
                <Stack.Screen name="NotFound" component={NotFound} />
            </Stack.Navigator>
        </NavigationContainer >
    );
};

export default RootNavigation;
