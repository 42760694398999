import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type StarIconProps = {
    size?: number
}

function StarIcon({ size = 50 }: StarIconProps) {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 58 58"
      fill="none"
    >
      <Path
        d="M24.864 3.09c1.525-3.867 6.999-3.867 8.524 0l6.105 15.48 15.48 6.105c3.867 1.525 3.867 6.999 0 8.524l-15.48 6.105-6.105 15.48c-1.525 3.867-6.999 3.867-8.524 0l-6.105-15.48-15.48-6.105c-3.867-1.525-3.867-6.999 0-8.524l15.48-6.105 6.105-15.48z"
        fill="#fff"
        fillOpacity={0.25}
      />
    </Svg>
  );
}

export default StarIcon;
