import './wdyr';
import 'react-native-gesture-handler';
// Some internal React-Native libraries are using removeListener and that shows a warning every time the app runs.
import { LogBox } from 'react-native';
LogBox.ignoreLogs(['EventEmitter.removeListener']);

import React from 'react';
import { Platform } from 'react-native';
import { setResponseInterceptors } from './src/apiCalls/customAxios';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SafeAreaProvider, initialWindowMetrics } from 'react-native-safe-area-context';
import { AppContextProvider } from './src/context/ContextProviders';
import RootNavigation from './src/navigations/RootNavigator';
import { MB_PopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_Toast } from '@mightybyte/rnw.components.toast';
import { useAppInitialSetup } from './src/hooks/useAppInitialSetup';
import { CustomToastConfig } from './src/components/helperComponents/CustomToastConfig';


// Response interceptors for axios. By default it is used to intercept certain errors and handle them in a special way.
setResponseInterceptors();

// Uncomment this when add native app.
// if (Platform.OS === 'android' || Platform.OS === 'ios') {
//   SplashScreen.preventAutoHideAsync().catch(warning => console.log('Warning. Failed to prevent splash screen auto hide ', warning));
// }

const App = () => {
  const isInitialSetupComplete = useAppInitialSetup();
  // isAppReady is intended to be set by rootNavigator when all necessary data has been pulled from the server
  // Uncomment this when add native app.
  // const [isAppReady, setIsAppReady] = useState(false);

  // Uncomment this when add native app.
  // useEffect(() => {
  //   if (isMobileApp && isAppReady && isInitialSetupComplete) {
  //     SplashScreen.hideAsync().catch((error) => console.error('Error when hiding splash screen', error));
  //   }
  // }, [isInitialSetupComplete, isAppReady]);


  if (!isInitialSetupComplete) {
    return null;
  }

  return (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>

      <AppContextProvider>
        {/*When add native app pass setIsAppReady as onReady handler*/}
        <RootNavigation onReady={() => { }} />

        <MB_PopUp />

        {process.env.NODE_ENV === 'development' && Platform.OS === 'web' &&
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        }

        <MB_Toast config={CustomToastConfig} absolutePositionForWeb />
      </AppContextProvider>
    </SafeAreaProvider>
  );
};

export default App;
