import React from 'react';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import sharedStyles from './Shared/styles';


const Home = () => {

    return (
        <ComponentWrapper
            containerStyle={sharedStyles.container}
        >
            {/** TODO: */}
        </ComponentWrapper>
    );

};

export { Home };
