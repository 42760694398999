export enum AnswerType {
    percentage = 'percentage',
    numeric = 'numeric',
}
export enum GroupBonuses {
    ageGroup = 'ageGroup',
    identity = 'identity',
    none = 'none',
}

export enum QuarterStatus {
    draft = 'draft',
    published = 'published',
    active = 'active',
    closed = 'closed',
}
export enum QuarterResultsStatus {
    standBy = 'standBy',
    calcInProgress = 'calcInProgress',
    calcCompleted = 'calcCompleted',
}
